@import 'src/style/globals/_functions';
.oneStopContainer {
  margin-bottom: clamp(5.3125rem, 4.1603rem + 5.7609vw, 8.625rem);
  margin-top: clamp(1.75rem, -0.9022rem + 13.2609vw, 9.375rem);
  background-color: var(--yellow);
  border-radius: 20px;
  padding-top: rem(30);
  padding-bottom: rem(30);
  display: flex;
  gap: rem(44);
  flex-direction: column;

  .oneStop {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(50);

    .right {
      width: 50%;
      height: 100%;
      padding-inline: clamp(0.3125rem, -0.2092rem + 2.6087vw, 1.8125rem);

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 20px;
      }

      @media (max-width: 1024px) {
        width: min(100%, 500px);
        height: clamp(18rem, 13.6522rem + 21.7391vw, 30.5rem);

        img {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
      }
    }

    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: rem(30);
      padding: clamp(1.125rem, 0.5598rem + 2.8261vw, 2.75rem) 0
        clamp(1.125rem, 0.5598rem + 2.8261vw, 2.75rem)
        clamp(1.125rem, 0.5598rem + 2.8261vw, 2.75rem);

      @media (max-width: 1024px) {
        align-items: center;
        width: 100%;
        padding: 0;
      }
    }

    h4 {
      font-size: var(--fs-header);
      font-weight: 900;
    }

    .cta {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: rem(18);

      a {
        background-color: var(--white);
        border-radius: 8px;
        border: 1px solid #111827;
        padding: clamp(0.375rem, 0.3098rem + 0.3261vw, 0.5625rem)
          clamp(1.125rem, 1.0598rem + 0.3261vw, 1.3125rem);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: rem(7);

        svg {
          fill: var(--dark-blue);
          height: clamp(1.125rem, 0.9293rem + 0.9783vw, 1.6875rem);
          width: clamp(1.125rem, 0.9293rem + 0.9783vw, 1.6875rem);
        }

        p {
          text-align: left;
          font-weight: 500;
          font-size: clamp(0.4375rem, 0.394rem + 0.2174vw, 0.5625rem);

          .available {
            font-size: var(--fs-base);
          }
        }
      }
    }

    @media (max-width: 1024px) {
      gap: rem(25);
      flex-direction: column;
    }
  }

  .slides {
    background: #dcb02b80;
    border: 1px solid #d6a71980;
    padding: rem(14) rem(23);
    border-radius: 8px;
    min-width: fit-content;
    white-space: nowrap;
    font-size: clamp(0.625rem, 0.538rem + 0.4348vw, 0.875rem);
  }
}
