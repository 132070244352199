@import 'src/style/globals/_functions';
.about {
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    margin-inline: auto;
    font-weight: 400;
    margin-bottom: clamp(1.25rem, 0.3804rem + 4.3478vw, 3.75rem);

    .top {
      font-size: var(--fs-header);
      font-weight: 900;
    }
  }

  .aboutContent {
    h4 {
      font-size: var(--fs-secondary-header);
      font-weight: 700;
      margin: 1.5rem 0 1rem;
    }

    h5 {
      font-size: clamp(0.9375rem, 0.8288rem + 0.5435vw, 1.25rem);
      font-weight: 700;
      margin: 1.5rem 0 1rem;
    }

    p {
      margin-bottom: 1rem;
      width: min(100%, 1000px);
    }

    ul {
      margin-left: clamp(0.3125rem, -0.2745rem + 2.9348vw, 2rem);
      margin-bottom: 1rem;
      padding-left: clamp(0.625rem, 0.3207rem + 1.5217vw, 1.5rem);
      width: min(100%, 632px);
    }

    ul li {
      list-style-type: disc;
      margin-bottom: 0.75rem;
    }

    strong {
      font-weight: bold;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}
