@import 'src/style/globals/_functions';
.roles {
  margin-top: clamp(5rem, 3.2609rem + 8.6957vw, 10rem);
  margin-bottom: clamp(3.4375rem, 2.6766rem + 3.8043vw, 5.625rem);

  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    margin-inline: auto;
    font-weight: 400;

    .top {
      font-size: var(--fs-secondary-header);
      font-weight: 900;
    }
  }

  .nav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: min(100%, 666px);
    margin-inline: auto;
    margin-top: clamp(3.125rem, 2.8207rem + 1.5217vw, 4rem);
    margin-bottom: clamp(2.5rem, 2.0652rem + 2.1739vw, 3.75rem);

    button {
      text-transform: capitalize;
      transition: color 250ms ease;
      font-weight: 700;
      border-bottom: 2px solid var(--white);

      &:hover {
        color: #103176;
      }
    }

    .active {
      border-bottom: 2px solid #111827;
    }
  }
}

.appRoleContents {
  display: flex;
  gap: rem(40);

  .left {
    width: 35%;
    position: relative;
    display: flex;
    align-items: flex-end;
    border-radius: 16px;

    .overlay {
      background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -10;
      border-radius: 16px;
    }

    .bannerImg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -15;
      border-radius: 16px;
      object-fit: cover;
    }

    .bannerContent {
      padding: rem(24);
      margin-bottom: rem(40);
      color: var(--white);
      font-size: clamp(0.9375rem, 0.8288rem + 0.5435vw, 1.25rem);

      .cta {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: rem(15);
        gap: clamp(0.625rem, 0.4076rem + 1.087vw, 1.25rem);

        a {
          padding: clamp(0.5625rem, 0.4755rem + 0.4348vw, 0.8125rem);
          border-radius: 100%;
          background-color: var(--white);

          svg {
            fill: var(--dark-blue);
            height: clamp(1.125rem, 0.9728rem + 0.7609vw, 1.5625rem);
            width: clamp(1.125rem, 0.9728rem + 0.7609vw, 1.5625rem);
          }
        }
      }
    }
  }

  .right {
    width: 65%;
    background: #f9fafb;
    border-radius: 16px;
    padding: rem(53);

    .model {
      padding: rem(10) rem(43);
      background-color: var(--dark-blue);
      color: var(--white);
      width: max-content;
      border-radius: 8px;
      text-transform: capitalize;
      margin-bottom: rem(45);
    }

    .modelContents {
      display: flex;
      gap: rem(40);

      .content {
        display: flex;
        gap: rem(15);
        margin-bottom: rem(20);

        svg {
          width: 100%;
          height: 100%;
        }

        .icon {
          padding: 10px;
          border-radius: 100%;
          width: 41px;
          height: 41px;
          background: #ffffff;
        }

        .leftContent {
          display: grid;
          grid-template-rows: 20px calc(100% - 34px);
          place-items: center;
          margin-top: clamp(0.1875rem, 0.0897rem + 0.4891vw, 0.4688rem);
          gap: rem(14);
        }

        .rightContent {
          display: flex;
          flex-direction: column;
          gap: 2px;

          p:nth-child(1) {
            font-size: clamp(0.9375rem, 0.8288rem + 0.5435vw, 1.25rem);
            font-weight: 700;
          }
        }

        span {
          width: 2px;
          height: max(100%, 45.07px);
          border-radius: 2px;
          background: #06080e;
          display: none;
        }

        &:last-child {
          margin-bottom: 0;

          span {
            display: none;
          }
        }
      }

      .phone {
        width: 40%;
        height: 400px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    background: #f9fafb;
    gap: rem(30);
    margin-inline: auto;
    width: min(100%, 690px);
    border-radius: clamp(0.5rem, 0.3261rem + 0.8696vw, 1rem);
    padding: rem(30) clamp(0.625rem, -0.2446rem + 4.3478vw, 3.125rem);

    .left {
      width: 100%;

      .overlay,
      .bannerImg {
        display: none;
      }

      .bannerContent {
        color: var(--dark-blue);
        margin-bottom: 0;
        padding: 0;

        .cta a {
          background-color: var(--dark-blue);

          svg {
            fill: var(--white);
          }
        }
      }
    }

    .right {
      margin-inline: auto;
      width: 100%;
      border-radius: 0;
      padding: 0;

      .model {
        display: none;
      }

      .modelContents {
        .phone {
          width: 50%;
        }

        @media (max-width: 600px) {
          flex-direction: column;

          .phone {
            width: 100%;
          }
        }
      }
    }
  }
}
