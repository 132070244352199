@import 'src/style/globals/_functions';
.doorStep {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: rem(15);
    margin-inline: auto;
    font-weight: 400;
    margin-bottom: clamp(1.25rem, 0.3804rem + 4.3478vw, 3.75rem);
    flex-wrap: wrap;

    .left {
      font-size: var(--fs-secondary-header);
      font-weight: 900;
    }

    .right {
      width: min(100%, 476px);
    }
  }

  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(40);
    position: relative;
    background-color: var(--yellow);
    border-radius: clamp(0.5rem, 0.2826rem + 1.087vw, 1.125rem);

    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: rem(30);

      .img {
        background: #e5f1ff40;
        padding-inline: clamp(0.9375rem, 0.6114rem + 1.6304vw, 1.875rem);
        padding-top: clamp(0.9375rem, 0.6114rem + 1.6304vw, 1.875rem);
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;

        &:first-child {
          padding-top: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        div {
          width: 200px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        @media (max-width: 900px) {
          display: none;
          // margin-bottom: clamp(0.9375rem, 0.6114rem + 1.6304vw, 1.875rem);

          div {
            width: 229px;
          }

          &:nth-child(2) {
            display: block;
          }
        }
      }
    }

    .right {
      width: 50%;
      padding: rem(30) clamp(0.75rem, 0.3587rem + 1.9565vw, 1.875rem)
        clamp(0.75rem, 0.3587rem + 1.9565vw, 1.875rem);

      h4 {
        font-size: clamp(0.9375rem, 0.8288rem + 0.5435vw, 1.25rem);
        font-weight: 900;
        margin-bottom: 15px;
        width: min(100%, 300px);
      }

      .point {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 15px;
        width: min(100%, 264px);
        font-weight: 500;
        margin-bottom: 15px;
      }

      .cta {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: rem(18);
        margin-bottom: 15px;

        a {
          background-color: var(--white);
          border-radius: 8px;
          border: 1px solid #111827;
          padding: clamp(0.375rem, 0.3098rem + 0.3261vw, 0.5625rem)
            clamp(1.125rem, 1.0598rem + 0.3261vw, 1.3125rem);
          display: flex;
          align-items: center;
          justify-content: center;
          gap: rem(7);

          svg {
            fill: var(--dark-blue);
            height: clamp(1.125rem, 0.9293rem + 0.9783vw, 1.6875rem);
            width: clamp(1.125rem, 0.9293rem + 0.9783vw, 1.6875rem);
          }

          p {
            text-align: left;
            font-weight: 500;
            font-size: clamp(0.4375rem, 0.394rem + 0.2174vw, 0.5625rem);

            .available {
              font-size: var(--fs-base);
            }
          }
        }

        @media (max-width: 900px) {
          align-items: center;
          justify-content: center;
        }
      }
    }

    @media (max-width: 900px) {
      flex-direction: column-reverse;
      gap: rem(15);

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
