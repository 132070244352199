@import 'src/style/globals/_functions';
.faq {
  margin-top: clamp(5rem, 3.2609rem + 8.6957vw, 10rem);

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    margin-inline: auto;
    font-weight: 400;
    margin-bottom: clamp(1.25rem, 0.3804rem + 4.3478vw, 3.75rem);

    .top {
      font-size: var(--fs-secondary-header);
      font-weight: 900;
    }
  }

  .accordion {
    height: max-content;
    width: min(100%, 768px);
    margin-inline: auto;

    .item {
      padding: clamp(1.25rem, 0.9891rem + 1.3043vw, 2rem);
      border-radius: 16px;

      &[data-state="open"] {
        background: var(--yellow);
      }
    }

    .trigger {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      gap: 15px;
      user-select: none;

      .title {
        font-size: clamp(0.875rem, 0.7663rem + 0.5435vw, 1.1875rem);
        font-weight: 500;
        text-transform: none;
        text-align: left;
      }

      svg {
        height: clamp(1.125rem, 0.9946rem + 0.6522vw, 1.5rem);
        width: clamp(1.125rem, 0.9946rem + 0.6522vw, 1.5rem);
      }

      .icon1 {
        display: none;
      }

      &[data-state="open"] > .icon1 {
        display: block;
      }

      &[data-state="open"] > .icon2 {
        display: none;
      }

      &[data-state="open"] > .title {
        font-weight: 700;
      }

      // &:hover {
      //   .title {
      //     font-weight: 700;
      //   }
      // }
    }

    .content {
      overflow: hidden;
      text-align: left;

      div {
        margin-top: rem(15);
      }

      &[data-state="open"] {
        animation: open 300ms ease-out;
      }

      &[data-state="closed"] {
        animation: close 300ms ease-out;
      }

      @keyframes open {
        from {
          height: 0;
        }

        to {
          height: var(--radix-collapsible-content-height);
        }
      }

      @keyframes close {
        from {
          height: var(--radix-collapsible-content-height);
        }

        to {
          height: 0;
          padding-top: 0;
        }
      }
    }
  }
}
