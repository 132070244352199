@import 'src/style/globals/_functions';
.hero {
  // min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cream);
  border-radius: 20px;
  gap: rem(50);

  .left {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: rem(30);
    padding: clamp(1.125rem, 0.5598rem + 2.8261vw, 2.75rem) 0
      clamp(1.125rem, 0.5598rem + 2.8261vw, 2.75rem)
      clamp(1.125rem, 0.5598rem + 2.8261vw, 2.75rem);

    @media (max-width: 1024px) {
      align-items: center;
      width: 100%;
      padding: 0;
    }
  }

  .right {
    width: 40%;
    height: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    @media (max-width: 1024px) {
      width: min(100%, 443px);
      height: clamp(18rem, 13.6522rem + 21.7391vw, 30.5rem);

      img {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .top {
      font-size: var(--fs-header);
      font-weight: 900;
      margin-bottom: rem(20);
    }

    .bottom {
      font-weight: 500;
      width: min(100%, 400px);
    }

    @media (max-width: 1024px) {
      margin-top: 20px;
      width: min(100%, 500px);
      text-align: center;
      align-items: center;
    }
  }

  .cta {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: rem(18);

    svg {
      fill: var(--white);
      height: clamp(1.125rem, 0.9293rem + 0.9783vw, 1.6875rem);
      width: clamp(1.125rem, 0.9293rem + 0.9783vw, 1.6875rem);
    }

    a {
      background-color: var(--dark-blue);
      border-radius: 8px;
      border: 1px solid #111827;
      padding: clamp(0.375rem, 0.3098rem + 0.3261vw, 0.5625rem)
        clamp(1.125rem, 1.0598rem + 0.3261vw, 1.3125rem);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: rem(7);

      p {
        color: var(--white);
        text-align: left;
        font-weight: 500;
        font-size: clamp(0.4375rem, 0.394rem + 0.2174vw, 0.5625rem);

        .available {
          font-size: var(--fs-base);
        }
      }
    }
  }

  .pops {
    position: absolute;
    padding: rem(19) rem(16);
    background-color: var(--white);
    box-shadow:
      12px 20px 15px 12px #0c0c0d0d,
      12px 20px 15px 12px #0c0c0d1a;
    border-radius: 12px;
    font-weight: 500;
    z-index: 300;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    gap: rem(25);
    flex-direction: column;
  }
}
